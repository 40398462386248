import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import FormEbook from '../components/FormEbook'
import Content from '../components/Content'
import Layout from '../components/Layout'
import Popup from '../components/Popup'
import './LandingPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons'

// Export Template for use in CMS preview
export const LandingPageTemplate = ({
  title,
  subtitle,
  featuredImage,
  body1,
  body2,
  body3,
  popupContent,
  destinationUrl,
  primaryColor,
  bodyImage,
  popupButtonContent,
  industry
}) => (
  <main className="LandingPage">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    >
      <Popup
        popupBackground={primaryColor}
        popupButtonColor={primaryColor}
        popupButtonContent={popupButtonContent}
      >
        <Content source={popupContent} />
        <FormEbook 
          name="Form Ebook"
          action={destinationUrl}
          industry={industry}
        />
      </Popup>
    </PageHeader>

    <section className="section">
      <div className="container">
        <div className="row">
          <div className="col-5" style={{maxWidth: "36rem"}}>
            <Content source={bodyImage}/>
          </div>
          <div className="col-6" style={{float: "right"}}>
            <Content source={body1} />
            <Popup
              popupBackground={primaryColor}
              popupButtonColor={primaryColor}
              popupButtonContent={popupButtonContent}
              buttonAlignment="taLeft"
            >
            <Content source={popupContent} />
            <FormEbook 
              name="Form Ebook"
              action={destinationUrl}
              industry={industry}
            />
            </Popup>
          </div>
        </div>
      </div>
    </section>

    <section className="section" style={{backgroundColor:"#f0f0f0"}}>
      <div className="container taCenter">
        <div className="row">
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
             <FontAwesomeIcon icon={faSearchLocation} size="4x" color="#444" />
            </div>
            <div style={{paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>SEJA ENCONTRADO NA INTERNET</h3>
              <p>Melhore sua visibilidade na web por meio do seu website, das mídias sociais e do Google Meu Negócio</p>
            </div>
          </div>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
              <FontAwesomeIcon icon={faUsers} size="4x" color="#444" />
            </div>
            <div style={{paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>CONSIGA MAIS PACIENTES</h3>
              <p>Aprenda como atrair pessoas que precisam dos seu serviços utilizando uma estratégia de marketing digital</p>
            </div>
          </div>
          <div className="col-4">
            <div style={{paddingTop: "2rem"}}>
            <FontAwesomeIcon icon={faHeart} size="4x" color="#444"/>
            </div>
            <div style={{paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "2rem", paddingBotton: "2rem"}}>
              <h3>PERCEPÇÃO DA SUA MARCA</h3>
              <p>Entenda como você pode criar conteúdos para ganhar a confiança e a admiração dos seus clientes</p>
            </div>
          </div>
        </div>      
      </div>
    </section>

    <section className="section">
      <div className="container">
        <Content source={body2} />
      </div>
    </section>

    <section className="section taCenter" style={{backgroundColor:primaryColor, color: "white"}}>
      <div className="container">
        <Content source={body3} />
        <Popup
            popupBackground={primaryColor}
            popupButtonColor="transparent"
            popupButtonContent={popupButtonContent}
            borderStyle="2px solid white"
          >
          <div className="taLeft">
            <Content source={popupContent} />
            <FormEbook 
              name="Form Ebook"
              action={destinationUrl}
              industry={industry}
            />
          </div>
        </Popup>
      </div>
    </section>

  </main>
)

const LandingPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
    navVisible={false}
  >
    <LandingPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)
export default LandingPage

export const pageQuery = graphql`
  query LandingPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        body1
        body2
        body3
        popupContent
        destinationUrl
        bodyImage
        primaryColor
        popupButtonContent
        industry
      }
    }
  }
`
