import React from 'react'
import { navigate } from 'gatsby-link'

import './Form.css'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function formFieldsHash(form) {
  const inputs = Array
    .prototype
    .slice
    .call(form.elements)
    .filter(e => e.localName === 'input')

  return inputs.reduce((hash, input) => {
    hash[input.name] = input.value
    return hash
  }, {})
}

export default ({
  name = 'Ebook Form',
  industry,
  subject = '', // optional subject of the notification email
  action = ''
}) => {

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formFieldsHash(form),
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <form
      className='Form'
      name={name}
      action={action + '/'}
      method="post"
      data-netlify="true"
      data-netlify-honeypot='_gotcha'
      onSubmit={handleSubmit}
    >
      <div className="Form--Group">
        <label className="Form--Label">
          <input
            className="Form--Input Form--InputText"
            type="text"
            placeholder="Nome*"
            name="firstname"
            required />
          <span>Nome</span>
        </label>
        <label className="Form--Label">
          <input
            className="Form--Input Form--InputText"
            type="text"
            placeholder="Sobrenome*"
            name="lastname"
            required />
          <span>Sobrenome</span>
        </label>
      </div>
      <label className='Form--Label'>
        <input
          className='Form--Input'
          type='email'
          placeholder='Email*'
          name='email'
          required />
      </label>
      <label className='Form--Label'>
        <input
          className='Form--Input'
          type='tel'
          placeholder='(DDD) Whatsapp'
          name='phone' />
      </label>
      <input type='text' name='_gotcha' style={{ display: 'none' }} />
      {!!subject && <input type='hidden' name='subject' value={subject} />}
      <input type='hidden' name='industry' value={industry} />
      <button
        className='Button Form--SubmitButton'
        type='submit'
        value='Enquire'
        style={{ background: "transparent", border: "2px solid white", alignSelf: "center" }}
      >Receber Ebook</button>
    </form>
  )
}
